.dl {
  color: white;
  background-color: var(--scc);
  border-radius: 15px;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
}

.dl[disabled] {
  color: white;
  background-color: var(--sccdeact);
  border-radius: 15px;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
}

.dldisabled {
  color: white;
  background-color: var(--sccdeact);
  border-radius: 15px;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
}

.scb {
  color: white;
  background-color: var(--scc);
  border-radius: 15px;
  border: none;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
}

.scb[disabled] {
  color: white;
  background-color: var(--scc);
  border-radius: 15px;
  border: none;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
}



.dl .spinning {
  background-color: var(--sccdeact);
  animation: spin 1s infinite linear;
}

.PSURLDownloadButton .spinning {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}