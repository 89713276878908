@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
    background-color: "black"
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  background-color: black !important;
  color: black !important;
}
