@import "./colors.css";

.MarkListButton .padded {
  width: 100%;
  padding: 20px 15px;
}

.scbline {
  color: var(--scc);
  border-top: 2px solid;
}

.scblinedouble {
  color: var(--scc);
  border-top: 5px double;
}

.padded {
  width: 100%;
  padding: 18px 0px;
}

.align_right {
  /*height: 100vh; Magic here */
  display: flex;
  justify-content: right;
  align-items: right;
}

.centered {
  /*height: 100vh; Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
}


.ExpandableDiversListComponent .Row .padded2 {
  background-color: rgb(100,100,100);
}

