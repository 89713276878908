.spac80 {
  padding-top: 80px
}


.spac100 {
  padding-top: 120px
}

.spac120 {
  padding-top: 120px
}

.spac140 {
  padding-top: 140px
}

.spac160 {
  padding-top: 180px
}