:root {
  --scc: #244d9e;
  --sccdeact: #566c99;
  --sccready: #15b0a3;
}

.fs28 {
  font-size: 28px;
  font-weight: bolder;
}

.fs22 {
  font-size: 22px;
  font-weight: bolder;
}

.fs20 {
  font-size: 20px;
  font-weight: bolder;
}

.fs18slim {
  font-size: 18px;
}

.fs18 {
  font-size: 18px;
  font-weight: bolder;
}

.SCToggleButton.active {
  background-color: var(--scc) !important;
  border-color: var(--scc) !important;
}

.SCToggleButton{
  background-color: var(--sccdeact);
    border-color: white;
}

.mynav {
 text-align:center;
 padding-top: 80px;
 width: 100%
}

.fullwidth {
 width: 100%
}

.whitebgnav {
  background-color: white !important;
}

.scubchiheader {
    background-color: var(--scc) !important;
    border: none
}

.scubchiheader_cw {
    background-color: var(--scc) !important;
    color: white !important;
    border: none
}

