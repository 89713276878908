@import "./colors.css";


.LoaderButton {
  color: white;
  background-color: var(--scc);
  border-radius: 15px;
  border: none;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
}

.LoaderButton .spinning {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}

.LoaderButton[disabled] {
  color: white;
  background-color: var(--sccdeact);
  border-radius: 15px;
  border: none;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
}

.test {
  padding: 35px 45px;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.headerbutton{
  background-color: var(--scc);
  color:white
}

.listbutton{
  color: black;
  border-width: 0;
}

.ListCheckButton {
  background-color: white;
  border-color: white;
  border-width: 0;
  color: lightgrey !important;
}

.ListCheckButtonActive {
  background-color: white !important ;
  color: var(--scc) !important ;
  border-color: white;
  border-width: 0;
}

.squaresymbol {
  color: white !important ;
  background-color: var(--scc);
  border-radius: 15px;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
}

.squaresymbol[disabled] {
  color: white;
  background-color: var(--sccdeact);
  border-radius: 15px;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
}


.squaresymboldisabled {
  color: white;
  background-color: var(--sccdeact);
  border-radius: 15px;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
}

.previous {
  color: var(--scc);
  background-color: white;
  border-radius: 15px;
  border: none;
  padding: 0px 0px;
  text-align: center;
  text-decoration: none;
}

.previous[disabled] {
  color: var(--sccdeact);
  background-color: white;
  border-radius: 15px;
  border: none;
  padding: 0px 0px;
  text-align: center;
  text-decoration: none;
}

.scb {
  color: white;
  background-color: var(--scc);
  border-radius: 15px;
  border: none;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
}

.scb[disabled] {
  color: white;
  background-color: var(--sccdeact);
  border-radius: 15px;
  border: none;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
}

.ready {
  color: white;
  background-color: var(--sccready);
  border-radius: 15px;
  border: none;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
}

.options {
  color: #2562a3;
  background-color: #f2f2f2 ;
  border-radius: 50px;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
}

.add {
  color: white;
  background-color: var(--scc);
  border-radius: 50%;
  border: none;
  padding: 15px 20px;

}

.addSelect {
  color: white;
  background-color: #6349da;
  border-radius: 15px;
  border: none;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
}

.cancel {
  color: var(--scc);
  background-color: lightgrey;
    border-radius: 15px;
  border: none;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
}

.cancelIcon {
  color: white;
  background-color: var(--scc);
  border-radius: 15px;
  border: none;
  padding: 7px 8px;
  text-align: center;
  text-decoration: none;
}

.cancelIcon[disabled] {
  color: white;
  background-color: var(--sccdeact);
  border-radius: 15px;
  border: none;
  padding: 7px 8px;
  text-align: center;
  text-decoration: none;
}